<template>
<Page id="administrator_users" title="Loggar">
    <Loader v-if="loading" loadingtext="Hämtar loggar..."></Loader>
    <div v-else class="logs">
        <Logrow v-for="log in logs" :key="log.id" :log="log" :user="getUser(log.userid)" :tournament="getTournament(log.tournamentid)">            
        </Logrow>
        <div class="text-center my-3">
            <b-button @click="fetchmore()">Ladda fler</b-button>
        </div>
    </div>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const Logrow = () => import("@/components/administrator/Logrow");

export default {
    name: "Logs",
    components: {
        Page,
        Loader,
        Logrow
    },
    data() {
        return {
            page: 0
        };
    },
    metaInfo: {
        title: "Loggar",
        meta: [{
            name: "description",
            content: "Valbara turneringar",
        }, ],
    },
    computed: {
        loading() {
            return this.$store.getters["administratorget/usersloading"] || this.$store.getters["administratorget/logsloading"] || this.$store.getters["administratorget/tournamentsloading"];
        },
        users() {
            var items = this.$store.getters["administratorget/users"];
            if (!items)
                return false;
            return items;
        },
        tournaments() {
            var items = this.$store.getters["administratorget/tournaments"];
            if (!items)
                return false;
            return items;
        },
        logs() {
            return this.$store.getters["administratorget/logs"]
        }
    },
    methods: {
        svg(path) {
            return this.$images.svg(path);
        },
        getUser(id) {
            return this.$functions.findById(this.users, "id", id);
        },
        getTournament(id){
            return this.$functions.findById(this.tournaments, "id", id);
        },
        fetchmore(){
            this.page++;
            this.$store.dispatch("administratorget/getLogs", this.page);
        }
    },
    mounted() {
        this.$store.dispatch("administratorget/getUsers");
        this.$store.dispatch("administratorget/getTournaments");
        this.$store.dispatch("administratorget/getLogs", this.page);
    },
};
</script>
